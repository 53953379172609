<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Storico Prodotti/Servizi Incassati"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>      
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      ></ExportExcelTable>
      <v-btn
        icon
        @click="toggleBarcode"
        :color="!!ghost ? 'red' : ''"
      >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-left align-left mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:external-filter>
            <OperatorsAutocomplete
              class="pb-5"
              v-model="externalFilter.value"
              :filled="false"
              :multiple="false"
              return-object
              dense
            ></OperatorsAutocomplete>
          </template>

          <template v-slot:custom-service="{filter}">
            <v-row class="pa-2" style="width:100%">
              <v-col>
                <ServiceSelector
                  style="width: 100%"
                  mandatory
                  @click="(service) => { filter.value = service }"
                  show-frequent-services
                ></ServiceSelector>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-service="{filter}">
            {{ !!filter.value ? filter.value.name : '' }}
          </template>

          <template v-slot:custom-item="{filter}">
            <v-row class="pa-2 py-4">
              <v-col>
                <ItemsAutocomplete
                  v-model="filter.value"
                  :filled="false"
                  :multiple="false"
                  return-object
                  dense
                ></ItemsAutocomplete>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-item="{filter}">
            {{ !!filter.value ? filter.value.description : '' }}
          </template>

          <template v-slot:chip-custom-operator="{filter}">
            {{ !!filter.value ? filter.value.lastname : '' }}
          </template>

          <template v-slot:custom-serviceOrItem="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-switch
                  v-model="filter.value"
                  false-value="service"
                  true-value="item"
                  color="orange"
                  :label="filter.value == 'service' ? 'Servizi' : 'Prodotti'"
                  :class="filter.value == 'service' ? 'custom-blue-switch' : 'custom-orange-switch'"
                  flat
                >
                </v-switch>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-serviceOrItem="{filter}">
            Solo: {{filter.value == 'service' ? 'Servizi' : 'Prodotti'}}
          </template> 

          <template v-slot:custom-customer="{filter}">
            <CustomersAutocomplete
              class="pb-5"
              v-model="filter.value"
              :filled="false"
              :multiple="false"
              return-object
              dense
            ></CustomersAutocomplete>
          </template>

          <template v-slot:chip-custom-customer="{filter}">
            Nome: {{ filter.value ? filter.value.firstname : '' }} {{ filter.value ? filter.value.lastname : '' }}
          </template>

          <template v-slot:custom-documentNumber="{filter}">
            <v-row class="pa-2 py-4">
              <v-col>
                <v-text-field
                  v-model="filter.value"
                  dense
                  placeholder="Numero Scontrino"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:chip-custom-documentNumber="{filter}">
            Numero Scontrino contiene {{ filter.value }}
          </template>

          <template v-slot:custom-cashDeskOperator="{filter}">
            <OperatorsAutocomplete
              class="pb-5"
              v-model="filter.value"
              :filled="false"
              :multiple="false"
              return-object
              dense
            ></OperatorsAutocomplete>
          </template>

          <template v-slot:chip-custom-cashDeskOperator="{filter}">
            Operatore Cassa: {{ filter.value ? filter.value.firstname : '' }} {{ filter.value ? filter.value.lastname : '' }}
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 70%" ref="dataTableWrapper" class="mb-10">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento Transazioni Di Cassa Incassati ..."
          item-value="uniqueId"
          :height="dataTableHeight"
          :headers="headers"
          :items="rows"
          :loading="loadingItems"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :show-actions="false"
          :show-select="false"
          :rowPerPageOptions="[200, 100, 50, 20]"
        >
          <template v-slot:custom-customer="{ item }">
            <v-chip>{{item.customer == " " ? "Cliente dal bar" : item.customer}}</v-chip>
          </template>
          <template v-slot:custom-documentNumber="{ item }">
            <v-chip color="#60b0f8">{{!!item.documentNumber ? item.documentNumber : 'Non Presente'}}</v-chip>
          </template>
          <template v-slot:custom-item="{ item }">
            {{item.object.name || item.object.description}}
          </template>
          <template v-slot:custom-quantity="{ item }">
            <v-chip color="green">{{item.object.quantity}}</v-chip>
          </template>
          <template v-slot:custom-price="{ item }">
            <v-chip color="#f39808">{{Number(item.object.realPrice).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
          </template>
          <template v-slot:custom-cashDeskOperatorName="{ item }">
            {{item.cashDeskOperatorName}}
          </template>
          <template v-slot:custom-operator="{ item }">
            <span v-if="!!item.object && !!item.object.operators && !!item.object.operators[0]">
              {{ item.object.operators.map(op => op.firstname + ' ' + op.lastname).join(', ') }}
            </span>
            <span v-else>
              Nessun Operatore
            </span>
          </template>

          <template v-slot:externalFooter>
            <TypeDataTable
              :headers="totalHeader"
              :items="totalRow"
              :show-select="false"
              :show-actions="false"
              disablePagination
            >
            </TypeDataTable>
          </template>
        </TypeDataTable>
      </div>

      <StandardDialog
        v-model="dialogBarcode"
        title="Area Riservata Sviluppatori" 
        :dialog-height="null"
        dialog-max-width="500px"
      >
        <ManualBarcodeInput
          v-model="ghostBarcode"
          scan-your-barcode-message="Immettere Codice" 
          @confirm="activeGhost"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DatePicker from "@/components/common/DatePickerCommon.vue";
import paymentCollectedService from "@/services/paymentCollected/paymentCollected.service.js";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import ServiceSelector from '@/components/common/ServiceSelector.vue';
import ItemsAutocomplete from "@/components/common/ItemsAutocomplete.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";
import operatorService from '@/services/operators/operators.service.js'
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "OperatorDetailPaymentTransactionDialog.vue",
  components: {
    DatePicker,
    FullScreenDialog,
    TypeDataTable,
    OperatorsAutocomplete,
    ManualBarcodeInput,
    AdvancedFilter,
    ServiceSelector,
    ItemsAutocomplete,
    CustomersAutocomplete,
    StandardDialog,
    ExportExcelTable,
  },
  mixins: [dateUtils],
  data: function () {
    let toady = new Date();
    toady.setHours(0, 0, 0, 0);
    let props = this.$router.resolve({ name: "Payments" });

    return {
      dialog: true,
      routeFather: props.href,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      page: 1,
      rowPerPage: 200,
      totalPages: 0,
      rows: [],
      totalRow: [],
      headers: [],
      totalHeader: [],
      loadingItems: false,
      selected: undefined,
      filterTypes: [
        { type: 'date', operator: 'equal', field: 'date', name: 'Data', label: 'Il', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'customer', name: 'Cliente', label: '', color: "", value: {}, icon: "mdi-account", blank: {} },
        { type: 'custom', operator: 'custom', field: 'serviceOrItem', name: 'Servizi o Prodotti', label: 'Servizi o Prodotti', color: "", value: undefined, icon: "mdi-nintendo-switch" },
        { type: 'cash', operator: 'equal', field: 'realPrice', name: 'Prezzo', label: 'Prezzo', color: "", value: undefined },
        { type: 'number', operator: 'equal', field: 'quantity', name: 'Quantità', label: 'Quantità', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'documentNumber', name: 'Numero Scontrino', label: 'Numero Scontrino', color: "", value: undefined, icon: "mdi-receipt" },
        { type: 'custom', operator: 'custom', field: 'cashDeskOperator', name: 'Operatore Cassa', label: 'Operatore Cassa', color: "", value: undefined, icon: "mdi-cash-register"}
      ],
      advanceFilters: [
        { type: 'custom', operator: 'custom', field: 'item', name: 'Prodotto', label: 'Prodotto', color: "", value: {}, icon: "mdi-spray-bottle", blank: {} },
        { type: 'custom', operator: 'custom', field: 'service', name: 'Servizio', label: 'Servizio', color: "", value: {}, icon: "mdi-room-service", blank: {} },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'operator', name: 'Operatore', label: 'Operatore', color: "", value: undefined },
      filtersValue: [
        { type: 'date', name: 'Data', field: 'date', operator: 'equal', value: toady },
      ],
      ghost: false,
      dialogBarcode: false,
      ghostBarcode: undefined,
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
    };
  },
  mounted() {
    this.fetchHeaders();
    
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.9;

    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.9;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);

    this.applyFilters()
    
    this.fieldsToExport = paymentCollectedService._fieldsToExportDetails();
    this.worksheetName = "Export Storico Prodotti/Servizi";
    this.fileName =
      "Storico_Prodotti_Servizi_" + this._buildDateToExport(new Date()) +
      ".xls";
  },
  methods: {    
    fetchPaymentsCollectedDetails() {
      let filters = [...this.filtersValue, { type: 'custom', operator: 'custom', field: 'ghost', value: this.ghost }];

      this.loadingItems = true;
      paymentCollectedService
        .detailList(this.page, this.rowPerPage, filters)
        .then((results) => {
          if (this.rowPerPage != results.rowPerPage) {
            this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page && this.totalPages != 0) {
            this.page = this.totalPages;
          }
          this.rows = results.rows;
          this.totalRow = results.totalRow;
          this.loadingItems = false;
          this.fetchHeaders()
        });
    },
    async fetchExcel() {
      let filters = [...this.filtersValue, { type: 'custom', operator: 'custom', field: 'ghost', value: this.ghost }];
      let results = await paymentCollectedService.detailList(this.page, this.rowPerPage, filters)

      return results.rows
    },
    fetchHeaders() {
      this.loadingHeaders = true;
      paymentCollectedService.detailsFields(this.ghost).then((headers) => {
          this.headers = headers
          paymentCollectedService.totalDetailsFields(this.ghost).then((headers) => {
          this.totalHeader = headers
          this.loadingHeaders = false;
        });
      });
    },
    applyFilters(filters) {
      this.fetchPaymentsCollectedDetails();
    },
    toggleBarcode(){
      this.dialogBarcode = true
    },
    activeGhost(){
      operatorService.canPerformOperation(this.ghostBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost      
          this.fieldsToExport = paymentCollectedService._fieldsToExport(this.ghost);
          this.applyFilters()
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
      this.ghostBarcode = undefined
      this.dialogBarcode = false
    },
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
      if(newVal) {
        this.fetchPaymentsCollectedDetails();
      }
    },
    page() {
      this.applyFilters();
    },
    rowPerPage() {
      this.applyFilters();
    },
  },
};
</script>

<style>
.custom-blue-switch .v-input--selection-controls__input div {
  color: blue !important;
  caret-color: blue !important;
}
.custom-orange-switch .v-input--selection-controls__input div {
  color: orange !important;
  caret-color: orange !important;
}
</style>